import React from 'react';
import '../../styles/App.css';

const LocationTile = ({ location, onClick, isSelected, markerId }) => {

    return (
        <div className={isSelected ? 'location-tile active' : 'location-tile'} onClick={(e) => (onClick ? onClick(e, location) : null)}>
            <div className='location-title'>{ location.title }</div>
            <div className='location-metadata'>
                {/\S/.test(location.city) && <div className='metadata-item location-city'>{ location.city }</div>}
                {/\S/.test(location.state_name) && <div className='metadata-item location-state'>{ location.state_name }</div>}
                {/\S/.test(location.country_name) && <div className='metadata-item location-country'>{ location.country_name }</div>}
            </div>
        </div>
    )
}

export default LocationTile