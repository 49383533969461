import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../components/auth/AuthProvider';
import axios from 'axios';
import '../styles/App.css';

function Login() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [code, setCode] = useState('');
    const [step, setStep] = useState(1);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);  // Access the AuthContext

    const requestVerification = async () => {
        try {
            await axios.post('https://ksheth.herokuapp.com/request-verification', { phone_number: phoneNumber });
            setStep(2); // Move to the next step where the user enters the code
            setError('');
        } catch (err) {
            setError('Failed to send verification code. Please try again.');
        }
    };

    const verifyCode = async () => {
        try {
            const response = await axios.post('https://ksheth.herokuapp.com/verify-code', { phone_number: phoneNumber, code });
            const jwt = response.data.access_token;
            const user = response.data.user;
            login(jwt, user);
            setError('');
            navigate('/dashboard');
        } catch (err) {
            setError('Invalid verification code. Please try again.');
        }
    };
    

    return (
        <div className='container container-page' style={{textAlign:'center'}}>
            <div className='modal-container'>
                {step === 1 && (
                    <div className='modal-login'>
                        <div>
                            <p>Enter your phone number</p>
                            <p className='subtext'>This is to verify your identity.</p>
                        </div>
                        <input
                            type="tel"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="2223334444"
                        />
                        <button onClick={requestVerification} className='button button-outline'>Send Code</button>
                    </div>
                )}
            
                {step === 2 && (
                    <div className='modal-login'>
                        <p>Enter the verification code</p>
                        <input
                            type="text"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            placeholder="XXXXXX"
                        />
                        <button onClick={verifyCode} className='button button-outline'>Verify Code</button>
                    </div>
                )}
            
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>
        </div>
    );
}

export default Login;