import React from "react"
import { Link } from "react-router-dom"
import config from '../../config.json'
import '../../styles/App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'

const AlbumTile = (props) => {
    return (
        <Link to={"/album/" + props.album.id} className="tile tile-album">
            <img className="tile-image" src={config.s3_url + '/' + props.album.path + '/small/' + props.album.file_name} />
            <div className="tile-info">
                <p>{props.album.title}</p>
                <p>{new Date(props.album.created).toISOString().split('T')[0]}</p>
                <p>{props.album.model}</p>
                <p>{/\S/.test(props.album.city) ? props.album.city + ", " : ""}{props.album.state_name ? props.album.state_name : ""} {props.album.country_name ? props.album.country_name : ""}</p>
            </div>
        </Link>
    )
}

export default AlbumTile